import request from '@/utils/request'


// 查询用户车辆认证列表
export function listCarAuth(query) {
  return request({
    url: '/user/user-car-auth/list',
    method: 'get',
    params: query
  })
}

// 查询用户车辆认证分页
export function pageCarAuth(query) {
  return request({
    url: '/user/user-car-auth/page',
    method: 'get',
    params: query
  })
}

// 查询用户车辆认证详细
export function getCarAuth(data) {
  return request({
    url: '/user/user-car-auth/detail',
    method: 'get',
    params: data
  })
}

// 新增用户车辆认证
export function addCarAuth(data) {
  return request({
    url: '/user/user-car-auth/add',
    method: 'post',
    data: data
  })
}

// 修改用户车辆认证
export function updateCarAuth(data) {
  return request({
    url: '/user/user-car-auth/edit',
    method: 'post',
    data: data
  })
}

// 删除用户车辆认证
export function delCarAuth(data) {
  return request({
    url: '/user/user-car-auth/delete',
    method: 'post',
    data: data
  })
}
